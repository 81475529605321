import React from 'react'
import styled from 'styled-components'

const Iframe = styled.iframe`
  margin-top: -126px;

  @media (max-width: 992px){
    height: 2000px;
  }
`

interface AfspraakIframeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_AfspraakIframe
}

const AfspraakIframe: React.FC<AfspraakIframeProps> = ({ fields }) =>  (
  <section>
    <div>
      <Iframe src={fields?.iframeUrl} width="100%" height="1600" frameBorder="0" title="Afspraak maken" scrolling="0" />
    </div>
  </section>
)

export default AfspraakIframe
